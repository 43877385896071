import NP from "number-precision";

export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "产品编号",
    dataIndex: "material__number",
    sorter: true,
    width: 120,
    customRender: (value, item) => item.material_number,
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
  },
  {
    title: "英文名称",
    dataIndex: "material_english_name",
  },
  {
    title: "产品条码",
    dataIndex: "material_barcode",
    width: 120,
  },
  {
    title: "产品规格",
    dataIndex: "material_spec",
    width: 80,
  },
  {
    title: "库存数量",
    dataIndex: "quantity",
    width: 80,
  },
  {
    title: "可用数量",
    dataIndex: "available_quantity",
    width: 80,
  },
  {
    title: "良品数量",
    dataIndex: "qualified_quantity",
    width: 80,
    customRender: (value, item) => NP.minus(item.available_quantity, item.unqualified_quantity),
  },
  {
    title: "不良品数量",
    dataIndex: "unqualified_quantity",
    width: 100,
  },
  {
    title: "单位",
    dataIndex: "material_unit",
    width: 60,
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 80,
    scopedSlots: { customRender: "action" },
  },
];
